.top-strip.me-block > .row > .column {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .me-block {width: auto;}
}

.service-block-wrap.me-block {
  .me-PanelCol.me-block {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}
.float-file-label {
  position: absolute;
  background: rgba(220,220,220,0.5);
  bottom: 0;
}

.me-CartChangePass input[type="password"],
.me-CartLogin input,
.me-CartResetPass input{
  border: 1px solid white;
  background: rgba(255,255,255,0.2);
  box-shadow: none;
  color: white;
  &:focus {
    border: 1px solid white;
    background: rgba(255,255,255,0.2);
    box-shadow: none;
    color: white;
  }
}