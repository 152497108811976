body { background-color: $COL2; }
body { color: $COL5; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL7;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL8;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL9;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL10;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL11;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL12;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL13;
font-family: 'Roboto';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL19;
font-family: 'Roboto';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 25px;

}
}
summary {
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Roboto';
font-weight: 300;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 20px;

}
body {
font-family: 'Roboto';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: $COL6}
a:hover {color: $COL14}
/* Service Block Mask Panel:11 */
.MES11 {
background-color: $COL16;
&:hover, &.hover { background-color: $COL20;}
color: $COL2;
padding: 15px;

@media #{$large-up} {
padding: 20px 30px;

}
 }
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: $COL2;
 }
cite.MEC11{
color: $COL2;
}
/* Organisation Group:17 */
.MES17 {
border-width: 0 0 0 15px;
border-style: solid;
border-color: $COL16;
 }
/* Organisation:16 */
details.MES16 {
& > article {border-width: 0 0 0 15px;
border-color: $COL20;
} }
/* Login Button:15 */
.MES15 {
border-width: 1px;
border-style: solid;
border-color: $COL2;
 }
/* Contact Form:14 */
.me-block.me-Form.MES14,  body.MES14 {background-color:transparent;
 color: $COL3;
 
 input[type='submit']{border-style: solid;background-color: $COL3;
&:hover {background-color: $COL21}
border-width: 0;
color: $COL2;
&:hover {color: $COL17}
padding: 10px 30px;

}
@include placeholder($COL3);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 0px 0px 2px 0px;
border-color: $COL3;
background-color: $COL1;
color: $COL3;
}
::-webkit-input-placeholder { color: $COL3; opacity: 0.5;}
:-moz-placeholder {color: $COL3; opacity: 0.5;} 
::-moz-placeholder {color: $COL3; opacity: 0.5;}
:-ms-input-placeholder {color: $COL3; opacity: 0.5;}

 .me-wrap-text a {color: $COL3;
} }
/* Footer Menu:13 */
nav.me-Menu.MES13 {
& .menu-item.MEC13, & .menu-item.MEC13 > div.MEC13{ & > a.MEC13{color: $COL2;
text-transform: uppercase;
}
 &:hover > a.MEC13{color: $COL17;
}
 }
&.horizontal > .menu-item.MEC13 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC13 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC13 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC13 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Credit Panel:12 */
.MES12 {
background-color: $COL17;
color: $COL21;
font-size: 11.9px;
@media #{$medium-up} {
font-size: 12.8px;
};
padding: 10px 15px;

 }
cite.MEC12{
color: $COL21;
font-size: 11.9px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* Light:10 */
.MES10 {
background-color: $COL17;
color: $COL18;
 }
h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: $COL18;
 }
cite.MEC10{
color: $COL18;
}
/* Primary:2 */
.MES2 {
background-color: $COL3;
color: $COL4;
&:hover { color: $COL2;}
 }
/* Light:9 */
.MES9 {
background-color: $COL17;
color: $COL18;
 }
/* Slider Mask:8 */
.MES8 {
background-color: $COL16;
color: $COL2;
 }
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL2;
 }
cite.MEC8{
color: $COL2;
}
/* Home Slider:7 */
.MES7 {
& .slider-arrow {color: $COL15;
font-size: 50px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: $COL2;
} }
/* Sign in Button:6 */
.MES6 {
background-color: $COL1;
&:hover { background-color: $COL1;}
color: $COL3;
&:hover { color: $COL3;}
padding: 0 15px 0 30px;

 }
/* Main Menu:5 */
nav.me-Menu.MES5 {
.menu-item.MEC5{background-color: $COL3;
 &:hover {background-color: $COL22}
}
& .menu-item.MEC5, & .menu-item.MEC5 > div.MEC5{ & > a.MEC5{color: $COL2;
text-transform: capitalize;
}
 &:hover > a.MEC5{color: $COL2;
}
 }
&.horizontal > .menu-item.MEC5 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC5 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC5 .menu-item { border:0;
border-bottom-width: 1px;
border-color: $COL2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC5 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 50px 15px;}

& .sub-menu .menu-item a{padding: 10px;}


& > .menu-item.MEC5.active { background-color: $COL15;
& > a{ color: $COL3;}
 }
&.horizontal .menu-item.MEC5:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL20;}}
&.vertical .menu-item.MEC5:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL20;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC5{background-color: $COL20;
 &:hover {background-color: $COL3}
}
& .menu-item.MEC5, & .menu-item.MEC5 > div.MEC5{ & > a.MEC5{color: $COL2;
text-transform: capitalize;
}
 &:hover > a.MEC5{color: $COL17;
}
 }

}
}
 }
/* Top Strip Panel:4 */
.MES4 {
background-color: $COL2;
color: $COL3;
padding: 0;

 }
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: $COL3;
 }
a.MEC4 { color: $COL3;
&:hover { color: $COL3; }
 }
cite.MEC4{
color: $COL3;
}
/* Primary:3 */
.MES3 {
background-color: $COL3;
color: $COL4;
 }
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL4;
 }
a.MEC3 { color: $COL2;
&:hover { color: $COL17; }
 }
cite.MEC3{
color: $COL4;
}
/* Report:18 */
.MES18 {
background-color: $COL17;
color: $COL18;
border-width: 2px;
border-style: solid;
border-color: $COL17;
 }
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: $COL18;
 }
cite.MEC18{
color: $COL18;
}
