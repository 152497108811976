$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #ffffff; //
$COLFLAT2: #ffffff; //
$COL3: #0a3e5d; //
$COLFLAT3: #0a3e5d; //
$COL4: #ffffff; //
$COLFLAT4: #ffffff; //
$COL5: #1b1b1b; //
$COLFLAT5: #1b1b1b; //
$COL6: #0a3e5d; //
$COLFLAT6: #0a3e5d; //
$COL7: #1b1b1b; //
$COLFLAT7: #1b1b1b; //
$COL8: #1b1b1b; //
$COLFLAT8: #1b1b1b; //
$COL9: #1b1b1b; //
$COLFLAT9: #1b1b1b; //
$COL10: #1b1b1b; //
$COLFLAT10: #1b1b1b; //
$COL11: #1b1b1b; //
$COLFLAT11: #1b1b1b; //
$COL12: #1b1b1b; //
$COLFLAT12: #1b1b1b; //
$COL13: #1b1b1b; //
$COLFLAT13: #1b1b1b; //
$COL14: #1b1b1b; //
$COLFLAT14: #1b1b1b; //
$COL15: #f2f2f2; //Light
$COLFLAT15: #f2f2f2; //Light
$COL16: rgba(10,62,93,0.302); //Blue 30%
$COLFLAT16: #5c6c75; //Blue 30%
$COL17: #f2f2f2; //
$COLFLAT17: #f2f2f2; //
$COL18: #1b1b1b; //
$COLFLAT18: #1b1b1b; //
$COL19: #1b1b1b; //
$COLFLAT19: #1b1b1b; //
$COL20: rgba(10,62,93,0.702); //Blue 70%
$COLFLAT20: #2d5267; //Blue 70%
$COL21: #1b1b1b; //black
$COLFLAT21: #1b1b1b; //black
$COL22: rgba(242,242,242,0.478); //White transparent
$COLFLAT22: #b6b6b6; //White transparent
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
